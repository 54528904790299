/* Overriding ant defaults */
.mapboxgl-ctrl-scale {
    color: #00008f !important;
    border: 1px solid #00008f !important;
    border-top: #333 !important;
}

.ant-switch-checked {
    background-color: #00008f !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%2300008F%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F>%0A<%2Fsvg>%0A") !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%2300008F%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F>%0A<%2Fsvg>%0A") !important;
}

.ant-switch-inner {
    font-size: 16px !important;
}

.mapboxgl-ctrl-group {
    border-radius: 0%;
}

.mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none;
}

.ant-spin-dot-item {
    background-color: #00008f !important;
}

/* Overriding ant defaults */

.map__superContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
}

.map__container {
    position: relative;
    width: 100%;
    height: 100%;
}

.map__container--demo {
    width: 100%;
}

.map__wrapper {
    width: 75vw;
    height: 100%;
}

.parcel--loading {
    position: absolute;
    z-index: 1;
    transform: translate(-16px, -16px);
}

.legend__container {
    position: absolute;
    right: 24px;
    bottom: 32px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.legend__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.legend__item {
    width: 1.25em;
    height: 1.25em;
    margin-right: 16px;
    background-color: red;
}

.legend_text {
    max-width: 10em;
}

.legend__building--added {
    background-color: rgb(0 0 143 / 65%);
}

.legend__buildingParcels--added {
    background-color: rgb(255 255 255 / 45%);
    border: 1px solid #00008f;
}

.legend__parcels--unselected {
    background-color: rgb(223 119 93 / 10%);
    background-image: radial-gradient(circle, #df775d 5%, #fff 35%), radial-gradient(circle, #df775d 5%, #fff 35%);
    background-size: 4px 4px;
    border: 1.5px solid rgb(223 119 93 / 85%);
}

.legend__building--unselected--osm {
    background-color:transparent;
    border: 1px dashed #00008F;
}

.legend__building--added--osm {
    background-color: rgb(0 0 143 / 65%);
    border: 1.5px solid #00008f;
}

.geocode__modalContainer {
    padding-right: 5em;
    padding-left: 5em;
}

.geocode__submitButton {
    text-transform: uppercase;
}

/* stylelint-disable selector-id-pattern */
.geocode__input__container #basic_coords {
    padding: 16px 0.5em;
}

.pin__label {
    padding: 0.1em 16px;
    margin-bottom: 0.5em;
    font-weight: 800;
    color: #fff;
    background-color: #ef7661;
    border: 0.5px solid #333;
    border-radius: 16px;
    box-shadow:
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(
            0 0 0 / 10%
        );
}

.pin__label--isClientPin {
    padding: 0.1em 16px;
    margin-bottom: 0.5em;
    font-weight: 800;
    color: #fff;
    background-color: #5b7b73;
    border-radius: 16px;
    box-shadow:
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(
            0 0 0 / 10%
        );
}

.pin__label--index {
    padding: 0.1em 1em;
    margin-bottom: 0.5em;
    font-weight: 800;
    color: #333;
    background-color: #fff;
    border: 0.5px solid #333;
    border-radius: 16px;
}

.popPin {
    height: 65px;
    transition: all 1s;
}

.popPin:hover {
    transform: translateY(-5px);
}

.bottom-arrow {
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    display: inline-block;
    width: 0;
    height: 0;
    padding: 3px;
    margin: 0 auto;
    background-color: #fff;
    border: solid black;
    border-width: 0 0.5px 0.5px 0;
    transform: rotate(45deg);
}

.mapboxgl-marker {
    transform: translate(-50%, -50%);
}
