.page__title {
    margin-bottom: 0 !important;
}

.ant-btn-link:active,
.ant-btn-link:hover,
.ant-btn-link:focus {
    background: #1fe0 !important;
    border-color: #1fe0 !important;
}

.axa-btn-sm {
    font-size: 12px !important;
}

.download__text {
    text-decoration: underline;
}

.filtersActions--locations {
    flex-grow: 1;
}

.filtersActions__filtersContainer--locations {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
}

.filtersActions__filtersContainer {
    display: flex;
    flex-wrap: wrap;
}

.filterAction__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.filterAction__container--no-grow {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    width: auto;
}

.filtersActions__filtersContainer--selects {
    margin-top: 0.5em !important;

    /* margin-right: 32px !important; */
}

.filter_btn {
    padding-top: 20px;
}

.searchContainer--locations {
    flex-grow: 3;
    padding: 0 32px !important;
}

.ant-select-arrow {
    color: #00008f !important;
}

.ant-select-selector {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: 1 !important;
    color: #5f5f5f !important;
    letter-spacing: normal !important;
}

.ant-select-item-option-content {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: 1 !important;
    letter-spacing: normal !important;
}

.ant-select-item-option-active {
    background-color: #f5f5f5 !important;
}

.ant-select-selection-selected-value {
    width: 100% !important;
}

.pageBreadcrumb {
    margin-bottom: 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.pageBreadcrumb__link {
    color: #00008f;
}

.pageBreadcrumb__link:hover {
    font-weight: 700;
    color: #00008f;
}

.file_name {
    font-weight: 700;
    color: #00008f;
}

.locationName__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

.validationStatus__label {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

.validationStatus__label--non-reviewed,
.validationStatus__label--reviewed {
    color: #5f5f5f;
}

.validationStatus__label--validated {
    color: #1cc54e;
}

.validationStatus__label--error {
    color: #e22e12;
}

.locationName__label {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

/* overriding default row of pages.css because no scroll on locations table */
.locationRow:hover {
    cursor: pointer;
    background-color: rgb(181 208 238 / 20%) !important;
    border-left: 3px solid #3d39e1;
}

.multiFilter__container {
    min-width: 16em;
    margin-right: 32px !important;
}

.filter__tag {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 24px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal !important;
    color: #333 !important;
    letter-spacing: normal;
    background-color: #f5f5f5 !important;
    border: solid 1px #ccc !important;
    border-radius: 6px !important;
}

.filter__tag--counter {
    padding: 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
    background-color: #f5f5f5;
}

.filtersActions__filtersContainer--selects .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest > .ant-select-selection-item {
    margin-left: 0.5em;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.filtersActions__filtersContainer .ant-select-selection-overflow {
    flex-wrap: nowrap;
}

.filter__tag > .ant-tag-close-icon {
    height: 16px;
}

.filter_option .anticon-check > svg {
    fill: #00008f;
}

.multiFilter__container--confidence .ant-select-selection-overflow-item:first-of-type {
    margin-right: 0.5em !important;
}

.multiFilter__container--country .ant-select-selection-overflow-item:first-of-type {
    width: 60%;
    margin-right: 0.5em !important;
}

.filter_option--confidence {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}

.filters__summary__title {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.filters_summary_list__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-content: center;
    align-items: center;
    margin-top: 16px;
}

.filters_input {
    display: flex;
    align-content: center;
    align-items: center;
    min-width: 300px;
    padding: 8px;
    color: #343c3d;
    border: 1px solid #757575;
}

.summaryCriteria__block:not(:last-of-type) {
    padding-right: 16px;
    margin-left: 16px;
    border-right: 1px solid;
}

.summaryCriteria__block:last-of-type {
    margin-left: 16px;
}

.summaryCriteria__item,
.filterAction__label {
    padding-left: 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.summaryCriteria__title {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-weight: 800;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.filters__wrapper {
    display: flex;
    flex-direction: row;
    gap: 58px;
    justify-content: flex-end;
}

.filterAction__label {
    padding: 0;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.filters_deletion_action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 116px;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    color: #00008f;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: normal;
}

.filters_summary_list__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.locations_header__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.geocoding_quality__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    width: 45em;
    height: 4em;
}

.geocoding_quality_metrics__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.geocoding_quality__title {
    margin-left: 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    font-stretch: normal;
    line-height: 1;
    color: #333;
    text-align: right;
    letter-spacing: normal;
}

.quality_metric__container {
    padding: 0.75em 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    line-height: 1;
    color: #333;
    text-align: right;
    letter-spacing: normal;
    border-radius: 4px;
}

.quality_metric__tag {
    padding: 0.25em 16px;
    margin-right: 0.25em;
    font-weight: 800;
    background-color: #ddd;
    border-radius: 16px;
}

.quality_metric__tag--success {
    color: #fff;
    background-color: #138636;
}

.filter__locations {
    flex: 1 1 auto;
}

.filters_summary__container {
    margin-bottom: 24px;
}

.quickFilter__action {
    padding-top: 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #00008f;
    text-decoration: underline;
    letter-spacing: normal;
    cursor: pointer;
}

.quickFilter__action--hidden {
    visibility: hidden;
}

.quickFilter__action:hover,
.quickFilter__action:active,
.quickFilter__action:focus {
    font-weight: bold !important;
}

.filter_countrySelect__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
}

.filter_countrySelect__country--text {
    padding-left: 0.25em;
}

.multiFilter__container--country .ant-select-selection-overflow-item:first-of-type .filter_countrySelect__country--text {
    width: 60%;
    padding-left: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.seeAllLocationsOnMap__button {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 0.5em;
}

.selectResolution__children {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.selectResolution__counter {
    font-weight: 400;
    color: #9d9d9d;
}

.ant-select-item-option-selected .selectResolution__counter {
    margin-right: 0.5em;
}

.stop-scrolling {
    position: fixed;
    width: 100%;
    margin-top: 0;
}

.jvectormap-zoomin {
    display: none;
}

.jvectormap-zoomout {
    display: none;
}

.map-legend {
    position: absolute;
    left: 90%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.map-legend-button {
    top: -25px;
    right: 25px;
    width: fit-content;
}

.map-legend .ant-btn-default:focus,
.map-legend .ant-btn-default:hover {
    color: #343c3d !important;
}

.map-legend .ant-btn {
    font-weight: 600;
    color: #343c3d;
}

.map-legend-text {
    top: -25px;
    right: 25px;
    width: fit-content;
    margin: 16px 8px;
    font-weight: 600;
    background-color: #fff;
}
