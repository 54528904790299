.loading_content__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.loading_content__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    height: 15em;
}

.loading_content__container--small {
    height: auto;
}

.loading_content_description__container {
    align-self: flex-start;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.loading_content_description__container_main {
    align-self: center;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.loading_container {
    border-radius: 4px;
}

.loading_continue__button {
    height: 3em;
}

.loading_animation__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.waveBar {
    width: 10px;
    height: 30px;
    margin: 10px 5px;
    margin-right: 3px;
    margin-left: 3px;
    background-color: #c9c9e7;
    border-radius: 3px;
    animation: waveBar 3s linear infinite;
}

.waveBar:nth-child(2) {
    animation-delay: 0.1s;
}

.waveBar:nth-child(3) {
    animation-delay: 0.2s;
}

.waveBar:nth-child(4) {
    animation-delay: 0.3s;
}

.waveBar:nth-child(5) {
    animation-delay: 0.4s;
}

.waveBar:nth-child(6) {
    animation-delay: 0.5s;
}

.waveBar:nth-child(7) {
    animation-delay: 0.6s;
}

.waveBar:nth-child(8) {
    animation-delay: 0.7s;
}

.waveBar:nth-child(9) {
    animation-delay: 0.8s;
}

.waveBar:nth-child(10) {
    animation-delay: 0.9s;
}

.waveBar:nth-child(11) {
    animation-delay: 1s;
}

.waveBar:nth-child(12) {
    animation-delay: 1.1s;
}

.waveBar:nth-child(13) {
    animation-delay: 1.2s;
}

.waveBar:nth-child(14) {
    animation-delay: 1.3s;
}

@keyframes waveBar {
    0% {
        background-color: #c9c9e7;
        transform: scale(0.3);
    }

    50% {
        background-color: #00008f;
        transform: scale(1);
    }

    100% {
        background-color: #c9c9e7;
        transform: scale(0.3);
    }
}
