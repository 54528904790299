.infoTooltip_header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    align-items: center;
    justify-content: space-between;
    background-color: #00008f;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.infoTooltip__title {
    width: 100%;
    padding: 0 0.75em;
    margin: 0 !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    color: #fafafa !important;
    text-align: center;
}

.infoTooltip__action__container {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 0.25em;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: normal;
    cursor: pointer;
}

.infoTooltip__action__icon {
    padding-right: 0.5em;
}

.infotooltip__action__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.infoTooltip__action__link {
    padding: 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    color: #00008f;
    text-decoration: underline;
    letter-spacing: normal;
    cursor: pointer;
}

.infoTooltip__closeButton {
    display: flex !important;
    align-self: flex-start;
    padding: 0 0.25em 0 0 !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.infoTooltip__goBackButton {
    display: flex !important;
    align-items: center;
    align-self: center;
    padding: 0 !important;
    margin-left: 16px !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.infoTooltip__container {
    position: fixed;
    z-index: 15;
    display: flex;
    flex-direction: column;
    width: 20em;
    max-width: 20em;
    padding: 0;
    background-color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.infoTooltip__dataContainer {
    padding-top: 0;
}

.infoTooltip__dataContainer .ant-list-items li {
    padding: 0.5em 16px;
}

.infoTooltip__dataContainer .ant-list-items li:nth-child(even) {
    background-color: #f5f5f5 !important;
}

.infoTooltip__dataContainer .ant-typography {
    margin-bottom: 0 !important;
}

.infoTooltip__dataContainer .ant-list-item-meta-title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.infoTooltip__dataContainer .ant-list-item-meta-description {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    text-transform: initial;
    letter-spacing: normal;
}

.axa-button--ghost.infoTooltip__action__container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.dataContainer__item,
.dataContainer__subItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.tooltip__label--key {
    line-height: 24px;
    color: #00008f;
    text-transform: capitalize;
}

.tooltip__label--value {
    font-weight: 700;
    line-height: 16px;
    color: #6b6b76;
    text-align: left;
    text-transform: none;
}
