/* Overriding ant defaults */
.ant-popover {
    z-index: 1500 !important;
}

.ant-upload-list-item-info {
    padding: 0;
    margin: 16px 0;
}

.ant-upload-list-item-name,
.ant-upload-text-icon > span {
    color: #4976ba !important;
}

/* Overriding ant defaults */

.uploadPopover__container {
    width: 58em;
    min-width: 25em;
    padding: 24px;
}

.uploadPopover__container button.ant-btn-link {
    padding-right: 0;
}

.uploadPopover__container button.ant-btn-link:hover,
.uploadPopover__container button.ant-btn-link:focus,
.uploadPopover__container button.ant-btn-link:active {
    font-weight: 700;
    border-color: transparent;
}

.uploadPopover__container--actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.queryFormUpload__container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.queryFormUpload__container > span {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.queryFormDragAndDrop__area > span > div > p.ant-upload-drag-icon > span {
    color: #00008f !important;
}

.queryFormUpload__container > div.ant-upload.ant-upload-select.ant-upload-select-text > span > button > span {
    color: rgb(0 0 143 / 85%);
}

.uploadPopover__upload--actions > button {
    margin-right: 16px;
}

.uploadPopover__upload--actions > button:nth-child(2) {
    margin-left: 16px;
}

.queryFormUpload__container > div.ant-upload.ant-upload-select.ant-upload-select-text > span > button {
    border: 1px solid rgb(0 0 143);
}

.queryFormDragAndDrop__area {
    height: auto !important;
    margin-top: 32px !important;
}

.queryFormDragAndDrop__area:hover {
    border-color: #00008f !important;
}

.uploadPopover__upload--actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    margin-top: 32px;
    text-align: right;
}

.uploadPopover__title__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}

.uploadPopover__title {
    padding: 0.5em 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.uploadPopover__title__closeButton {
    padding-right: 24px;
    color: #00008f;
    cursor: pointer;
    background: none;
}

.ant-popover-inner-content {
    padding: 0 !important;
}

.ant-upload-text {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px !important;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    color: #7f7f7f !important;
    letter-spacing: normal;
}

.ant-upload.ant-upload-drag {
    height: 7em !important;
    background-color: #f5f5f5 !important;
    border: solid 1px #e5e5e5 !important;
    border-radius: 1px !important;
}

.queryFormUpload__inputs__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    height: 5em;
}

.queryFormUpload__input__container {
    width: 50%;
}

.queryFromUpload__labels__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
}

.queryFormUpload__container__col--1 > div:nth-child(n+2) {
    padding-top: 16px;
}

.queryFromUpload__label__title {
    padding-top: 0.5em;
    padding-bottom: 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-weight: 600;
    font-stretch: normal;
    color: #333;
    letter-spacing: normal;
}

.queryFormUpload__input,
.queryFormUpload__input:-webkit-autofill:focus,
.queryFormUpload__input:-webkit-autofill:active,
.queryFormUpload__input:-webkit-autofill .queryFormUpload__input:-webkit-autofill:hover {
    height: 3em;
    padding: 16px !important;
    color: #333 !important;
    background-color: #fff !important;
    border: solid 1px #999 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.queryFormUpload__input::placeholder {
    font-style: italic !important;
    color: #5f5f5f !important;
}

.ant-form-item-has-error .ant-input:not(.ant-input-disabled) {
    border-color: #e22e12 !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: #e22e12 !important;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

.uploadPopover__wrapper {
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.uploadPopover__description__container {
    width: 95%;
    padding: 24px 24px 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}
