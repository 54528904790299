.data_privacy_warning_content__container {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.data_privacy_warning__container {
    width: 70% !important;
}

.data_privacy_warning_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 15em;
    padding: 0 18px;
    margin-left: 3em;
}

.data_privacy_warning_disclaimer__text {
    margin-bottom: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.data_privacy_warning_disclaimer__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.data_privacy_warning_illustration {
    width: 10em;
    height: 15em;
}
