.warning_container .ant-modal-title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.warning_container .ant-modal-body {
    padding-bottom: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.warning_container .ant-modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 24px;
    border-top: none;
}

.warning_container .ant-modal-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.warning__description--bold {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    color: #333;
    letter-spacing: normal;
}

.warning_container--large {
    width: 60em !important;
}

.warning_container--large .ant-modal-content {
    height: 30em !important;
}

.warning_container--large .ant-modal-body {
    padding-top: 0 !important;
}

.warning_container--extralarge {
    width: 1303px !important;
}
