#root {
    height: 100vh;
}

.ant-modal-close-x {
    color: #00008f;
}

.ant-layout {
    background-color: #fafafa !important;
}

.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.logo {
    display: flex;
    margin-left: 24px;
}

/* Overriding ant defaults */
.ant-layout-header {
    padding: 0 !important;
    line-height: 4em !important;
    background-color: #fff;
}

.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-item-only-child,
.ant-menu > .ant-menu-item-selected {
    margin: 0;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent !important;
}

.ant-upload-list-item {
    margin-top: 0 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
    color: #fff;
    background: #00008f;
    border-color: #00008f;
}

/* Overriding ant defaults */

.axa-button__cancel {
    margin-right: 8px;
    font-weight: 700;
    color: #f07662;
    text-shadow: none;
    background: #fff;
    border: 2px solid #f07662;
    box-shadow: none;
}

.axa-button__cancel:hover {
    color: #fff;
    background: #f07662;
    border-color: #f07662;
}

.axa-button--ghost {
    font-weight: bold;
    color: #00008f;
    text-shadow: none;
    text-transform: uppercase;
    background: #fff;
    border: 2px solid #00008f;
    box-shadow: none;
}

.axa-button--ghost:hover,
.axa-button--ghost:active,
.axa-button--ghost:focus {
    font-weight: bold;
    color: #fff;
    text-shadow: none;
    background: #00008f;
    border: 2px solid #00008f;
    box-shadow: none;
}

.axa-button--ghost.ant-popover-disabled-compatible-wrapper,
.axa-button--ghost.ant-popover-disabled-compatible-wrapper > button:disabled {
    width: 100%;
    font-weight: bold;
    text-shadow: none;
    text-transform: uppercase;
    background: transparent;
    border: 2px solid #999 !important;
    box-shadow: none;
}

.axa-button--validate {
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    background-color: #1cc54d;
    border: none;
}

.axa-button--validate:hover,
.axa-button--validate:active,
.axa-button--validate:focus {
    font-weight: 700;
    color: #fff;
    background: #129f3d;
}

.axa-button--validate[disabled] {
    color: #00000040;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
}

.axa-button--validate[disabled] > .axa-button--withLeftIcon > g {
    fill: #b7b7b7 !important;
}

.axa-button--notValidate {
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    white-space: normal;
    background-color: #c91432;
    border: none;
}

.axa-button--notValidate:hover,
.axa-button--notValidate:active,
.axa-button--notValidate:focus {
    font-weight: 700;
    color: #fff;
    background: #8b0e23;
}

.axa-button--notValidate[disabled] {
    background-color: #999;
}

.axa-button {
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    background: #00008f;
    border: 2px solid #00008f;
}

.axa-button:hover,
.axa-button:active,
.axa-button:focus,
.ant-input-search-button:hover,
.ant-input-search-button:active,
.ant-btn-link:active,
.ant-btn-link:hover,
.ant-btn-link:focus {
    background: #00005b;
    border-color: #00005b;
}

.ant-btn-link:active > svg > path {
    fill: #3d3dda;
}

.ant-btn-link:hover > svg > path {
    fill: #64b5f6;
}

.ant-btn-link:focus > svg > path {
    fill: #1f1f9c;
}

.axa-button__light {
    font-weight: 700;
    color: #fff;
    background: #e196aa;
    border-color: #e196aa;
}

.axa-button__light:hover,
.axa-button__light:focus,
.axa-button__light:active {
    color: #e196aa;
    background: #fff;
    border-color: #e196aa;
}

/* AXA Button */

.axa-button--withIcon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.axa-button--withLeftIcon {
    margin-right: 0.5em;
}

/* Secondary Button */
.axa-button__secondary {
    height: auto;
    min-height: 40px;
    font-weight: 700;
    color: #00008f;
    text-shadow: none;
    text-transform: uppercase;
    white-space: normal;
    background: #fff;
    border: 2px solid #00008f;
}

.axa-button--notValidate:disabled,
.axa-button__secondary:disabled {
    height: auto;
    font-weight: 700;
    text-shadow: none;
    text-transform: uppercase !important;
    background: transparent;
    box-shadow: none;
}

.axa-button__secondary:hover,
.axa-button__secondary:active,
.axa-button__secondary:focus {
    font-weight: bold;
    color: #fff;
    text-shadow: none;
    background: #00008f;
    border: 2px solid #00008f;
    box-shadow: none;
}

.axa-button--notValidate.ant-popover-disabled-compatible-wrapper,
.axa-button__secondary.ant-tooltip-disabled-compatible-wrapper {
    border: none;
}

.axa-button--notValidate.ant-popover-disabled-compatible-wrapper:hover,
.axa-button__secondary.ant-tooltip-disabled-compatible-wrapper:hover {
    background: none;
    border: none;
    border-radius: 0;
}

.axa-button--notValidate.ant-popover-disabled-compatible-wrapper > .ant-btn-primary {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1.14;
    text-align: center;
    letter-spacing: normal;
    border-radius: 0;
}

.axa-button--notValidate.ant-popover-disabled-compatible-wrapper > .ant-btn-primary,
.axa-button__secondary.ant-tooltip-disabled-compatible-wrapper > .ant-btn-primary {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    text-shadow: none;
    text-transform: uppercase;
}

.axa-button--notValidate.ant-popover-disabled-compatible-wrapper > .ant-btn-primary > svg > path,
.axa-button__secondary.ant-tooltip-disabled-compatible-wrapper > .ant-btn-primary > svg > path {
    fill: #b7b7b7;
}

.axa-button:hover.axa-button--withIcon > svg > path {
    fill: #00008f;
}

.axa-button__secondary:focus.axa-button--withIcon > svg > path,
.axa-button__secondary:hover.axa-button--withIcon > svg > path {
    fill: #fff;
}

.axa-header {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e8e8e8;
}

.axa-header__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: baseline;
    justify-content: flex-end;
    width: 100%;
    background: #fff !important;
}

.axa-header__gap {
    width: 20px;
}

.ant-menu-item,
.ant-menu-submenu-title {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    color: #00008f !important;
    text-transform: uppercase !important;
    letter-spacing: normal !important;
    border-bottom: 4px solid transparent !important;
}

.ant-menu-title-content {
    margin-left: 20px !important;
}

.ant-menu-horizontal > .ant-menu-item {
    vertical-align: unset !important;
    border: none !important;
    border-bottom: 4px solid transparent !important;
}

.ant-menu-horizontal {
    border-bottom: none !important;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu-submenu-title:hover {
    border-bottom: 4px solid #f075623f !important;
}

.ant-menu-item::after,
.ant-menu-item:hover::after,
.ant-menu-submenu::after {
    border-bottom: 4px solid transparent !important;
}

.ant-menu > .ant-menu-submenu-selected .ant-menu-submenu-title {
    border-bottom: 4px solid #f07662 !important;
}

.ant-progress-inner {
    background-color: #fff;
}

div > div.mapboxgl-marker > div > span > i {
    background-color: #00008f;
}

.load-more-parcels--container {
    position: fixed;
    top: 7em;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.load-more-parcels--button {
    margin-right: 3.5vw; 
    transform: translate(-50%, -0%);
    position:"relative";
    align-self:"center";
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #fff;
    text-shadow: none;
    text-transform: uppercase;
    letter-spacing: normal;
    background-color: #00008f;
    border: 1px solid #00008f;
    border-radius: 4px;
    outline: none;
}

.load-more-parcels--button > .ant-btn-loading-icon {
    color: #fff !important;
}

.load-more-parcels--button:hover {
    color: #00008f;
    background-color: #fff;
    border: 1px solid #fff;
}

h1 {
    font-family: "Publico Headline", serif !important;
    font-size: 32px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #00008f !important;
    letter-spacing: normal !important;
}

h2 {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #00008f !important;
    letter-spacing: normal !important;
}

h5 {
    margin-bottom: 16px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #333 !important;
    letter-spacing: normal !important;
}

.content__container {
    display: flex;
}

.logout__link {
    font-family: "Source Sans Pro", sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    color: #e22e12 !important;
    letter-spacing: normal !important;
}

.logout__link__icon {
    margin-right: 0.5em;
}

.ant-table-thead > tr > th:not(:last-child,
.ant-table-selection-column, .ant-table-row-expand-icon-cell, [colspan])::before {
    display: none;
}

.table__title--toRight {
    margin-right: 0.5em;
    text-align: right !important;
}

.table__title--toLeft {
    margin-right: 0.5em;
    text-align: left !important;
}

.beta__badge {
    padding-right: 0.5em;
    padding-left: 0.5em;
    margin-left: 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #00008f;
    text-transform: uppercase;
    letter-spacing: normal !important;
    background-color: #fff;
    border-radius: 16px;
}

.beta__badge__secondary {
    padding-right: 0.5em;
    padding-left: 0.5em;
    margin-left: 0.5em;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: normal !important;
    background-color: #00008f;
    border-radius: 16px;
}

.beta__badge__secondary:hover {
    color: #00008f;
    background-color: #fff;
}
