.map__layerSelector {
    position: absolute !important;
    bottom: 32px;
    z-index: 1 !important;
    margin: 20px !important;
    cursor: pointer;
    background-color: transparent !important;
}

.map__layerSelector__layerLabel {
    padding: 0 !important;
}

.map__layerSelector__layerLabelText {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    color: #00008f;
    letter-spacing: normal;
}

.map__layerSelector__layerLabelText--satellite {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    color: #fff;
    letter-spacing: normal;
}

.basemap__switcher__illustration {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-end;
    justify-content: space-around;
    width: 5em;
    height: 5em;
    background-origin: border-box;
    border: 3px solid #00008f;
    border-radius: 3px;
}

.basemap__switcher__illustration--road {
    background-image: url("assets/switcher-road.png");
    border: 3px solid #fff;
}

.basemap__switcher__illustration--satellite {
    background-image: url("assets/switcher-satellite.png");
}

.map__layerSelector__icon {
    width: 1.1em;
    height: 16px;
    background-image: url("assets/switcher-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.map__layerSelector__icon--white {
    width: 1.1em;
    height: 16px;
    background-image: url("assets/switcher-icon-white.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.map__layerSelector__layerLabel__description {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    user-select: none;
}
