.alert__container--map {
    position: absolute;
    top: 7em;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.alert__container {
    position: absolute;
    top: 5.5em;
    left: 50%;
    z-index: 100;
    width: 40%;
    padding: 0 0 24px 24px;
    border-radius: 4px;
    transform: translate(-50%, -40%);
}

.alert__container__map {
    position: relative;
    margin-top:48px ;
    z-index: 100;
    min-width: 27%;
    align-self: center;
    padding-left:24px;
    padding-bottom:22px;
    height:48px;
    border-radius: 4px;
    text-align: center;
    transform: translate(-3%, -40%);
}

.alert__container--withDescription {
    top: 8.5em;
}

.alert__container--success {
    background-color: #f3fff6;
    border: 1px solid #1cc54e;
}

.alert__container--warning {
    background-color: #fdf8f2;
    border: 1px solid #da7200;
}

.alert__container--info {
    background-color: #ebf0f8;
    border: solid 1px #4976ba;
}

.alert__container--error {
    background-color: #fdf2f2;
    border: 1px solid #914146;
}

.alert__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.alert__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
}

.alert__content__message__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.alert__content__message {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #343c3d;
    letter-spacing: normal;
}

.alert__content__description {
    width: 100%;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    margin-top: 0.5em;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #343c3d;
    letter-spacing: normal;
}

.alert__closeButton {
    display: flex;
    align-self: flex-end;
    padding: 0.25em 0.55em 0 0 !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.alert__closeButton__icon {
    margin-bottom: 24px;
    fill: #4976ba;
}

.alert__content__icon__container {
    margin-right: 16px;
}

.alert__content__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 2.3em;
}

.alert__errorRef__container {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #343c3d;
    letter-spacing: normal;
}

.alert__button__container {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 16px;
}

.alert__errorRef__code {
    text-transform: uppercase;
}

.toast__container {
    padding-top: 24px;
}
