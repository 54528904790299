.footer__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    align-items: center;
    justify-content: flex-end;
    height: 3em;
    padding: 0 16px;
    background-color: #00008f;
}

.footer__item {
    padding: 0 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #fff;
    text-transform: initial;
    letter-spacing: normal;
}
