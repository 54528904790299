.mapLoader__container {
    width: auto;
    height: 100%;
    background: #123d68;
    background-size: cover;
}

.dot {
    position: sticky;
    top: 20em;
    right: 15em;
    width: 300px;
    height: 300px;
    margin: auto;
}

.centraldot {
    position: absolute;
    top: 147px;
    left: 147px;
    width: 6px;
    height: 6px;
    background: rgb(32 150 243 / 100%);
    border-radius: 30px;
    transform-origin: 50% 50%;
    animation: animationDotCentral linear 3s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

.wave {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 260px;
    height: 260px;
    background: rgb(32 150 243 / 40%);
    border-radius: 200px;
    opacity: 0;
    transform-origin: 50% 50%;
    animation: animationWave cubic-bezier(0, 0.54, 0.53, 1) 3s;
    animation-delay: 0.9s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

.wave2 {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 260px;
    height: 260px;
    background: rgb(32 150 243 / 40%);
    border-radius: 200px;
    opacity: 0;
    transform-origin: 50% 50%;
    animation: animationWave cubic-bezier(0, 0.54, 0.53, 1) 3s;
    animation-delay: 1.07s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

@keyframes animationDotCentral {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    5% {
        transform: scale(2);
    }

    10% {
        opacity: 1;
        transform: scale(0.9);
    }

    11% {
        transform: scale(1.5);
    }

    12% {
        transform: scale(1);
    }

    28% {
        background: rgb(32 150 243 / 100%);
    }

    29% {
        background: rgb(255 255 255 / 100%);
    }

    31% {
        background: rgb(32 150 243 / 100%);
    }

    33% {
        background: rgb(255 255 255 / 100%);
    }

    35% {
        background: rgb(32 150 243 / 100%);
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes animationWave {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    1% {
        opacity: 1;
    }

    10% {
        background: rgb(32 150 243 / 40%);
    }

    100% {
        background: rgb(32 150 243 / 0%);
        transform: scale(1);
    }
}
