.skipFeedback__container {
    display: flex;
    height: 116px;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.skipFeedback__container:hover {
    cursor: pointer;
}

.skipFeedback__description {
    width: 90%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.skipFeedback__description__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
}

.skipFeedback__button--text {
    text-transform: uppercase;
}

.skipFeedback__button__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.skipFeedback__close {
    color: #00008f !important;
}

.skipFeedback__close:hover {
    cursor: pointer;
}
