.floatingInfoPanel__container {
    position: absolute;
    top: 10em;
    right: 16px;
    z-index: 15;
    width: 25em;
    max-width: 25em;
    min-height: 30em;
    max-height: 80em;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.floatingInfoPanel__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    align-items: center;
    justify-content: space-between;
    height: 2.5em;
    background-color: #00008f;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.floatingInfoPanel__closeButton {
    display: flex !important;
    align-self: flex-start;
    padding: 0 0.25em 0 0 !important;
    color: #fff;
    background: none;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.floatingInfoPanel__title {
    width: 100%;
    padding: 0.25em 0 !important;
    margin: 0 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #fafafa !important;
    text-align: center;
    letter-spacing: normal !important;
}

.floatingInfoPanel__container .ant-tabs-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    width: 7em;
    height: 2.5em;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    text-align: center;
    letter-spacing: normal;
}

.floatingInfoPanel__container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.floatingInfoPanel__container .ant-tabs-tab:hover {
    color: #00008F !important;
}

.floatingInfoPanel__container .ant-tabs-ink-bar {
    height: 3px !important;
    background: #00008F !important;
}

.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.floatingInfoPanel__container .ant-tabs-top > .ant-tabs-nav, {
    margin: 0 !important;
}

.floatingInfoPanel__container .ant-tabs-tabpane {
    max-height: 25em;
    overflow-y: auto;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.floatingInfoPanel__container .ant-tabs-nav-more {
    display: none;
}

.floatingInfoPanel__dataContainer .ant-tabs-nav-list {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.floatingInfoPanel__dataContainer .ant-list-items li {
    padding: 8px 16px;
}

.floatingInfoPanel__dataContainer .ant-list-items li:nth-child(even) {
    background-color: #f5f5f5 !important;
}

.floatingInfoPanel__dataContainer .ant-list-item-meta-title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.floatingInfoPanel__dataContainer .ant-list-item-meta-description {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    text-transform: initial;
    letter-spacing: normal;
}
