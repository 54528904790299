/* Overriding ant defaults */
.ant-steps-item-title {
    font-weight: 700;
}

.anticon-copy {
    color: #00008f !important;
}

.ant-typography-copy,
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
    color: #9fd9b4 !important;
}

/* Overriding ant defaults */

.locationData__link {
    line-height: 1.25em;
    color: #00008f;
    text-decoration: underline;
    text-transform: none;
}

.locationData__link--hover:hover {
    font-weight: 700;
    color: #00008f;
    text-decoration: underline;
}

.infoControlPanel {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 18em;
    height: 100vh;
    line-height: 2;
    color: #6b6b76;
    text-transform: uppercase;
    background: #fff;
    outline: none;
    box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
}

.infoControlPanel__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.infoControlPanel_title {
    margin-bottom: 0 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #fff !important;
    text-transform: none !important;
    text-transform: uppercase !important;
    letter-spacing: normal !important;
}

.infoControlPanel_actionsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: space-evenly;
    height: 14em;
    min-height: 14em;
    padding: 0 16px;
    background-color: #fafafa;
    box-shadow: 0 0 14px 0 rgb(0 0 0 / 17%);
}

.infoControlPanel_actionsContainer > button {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1.14;
    text-align: center;
    letter-spacing: normal;
}

.infoControlPanel__goToPrevious {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    color: #00008f;
    letter-spacing: normal;
}

.infoControlPanel__goToPrevious:hover {
    cursor: pointer;
}

.infoControlPanel__goToPrevious--disabled {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    color: #7f7f7f;
    letter-spacing: normal;
    cursor: not-allowed;
}

.infoControlPanel__goToPrevious .anticon-arrow-left,
.infoControlPanel__goToPrevious--disabled .anticon-arrow-left {
    padding-right: 16px;
}

.infoControlPanel_InformationTitle {
    padding: 0.5em 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    text-transform: none;
    letter-spacing: normal;
    background-color: #e5e5e5;
}

.locationData__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.locationData__container .ant-list-items li {
    padding: 0.5em 16px;
}

.locationData__container .ant-list-items li:nth-child(even) {
    background-color: #f5f5f5 !important;
}

.locationData__container .ant-list-item-meta-content {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    text-transform: none;
    letter-spacing: normal;
}

.infoControlPanel_item__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.infoControlPanel_item__description {
    font-weight: normal;
}

.infoControlPanel_item__description > .ant-typography {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0 !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.locationData__wrapper {
    height: 75vh;
    padding-right: 16px;
    overflow-y: scroll;
    box-shadow: inset 0 -5px 6px -7px rgb(0 0 0 / 40%);
}

.locationData__label__key {
    line-height: 24px;
    color: #00008f;
    text-transform: capitalize;
}

.locationData__label__not-comments {
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    text-transform: none;
}

.locationData__label__comments {
    font-weight: 400;
    line-height: 1.25em;
    text-align: justify;
    text-transform: initial;
}

.locationData__action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.infoControlPanel_counter__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0.75em 16px;
    background-color: #00008f;
}

.infoControlPanel_counter__label {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #fff;
    text-transform: none;
    letter-spacing: normal;
}

.infoControlPanel__goToPrevious__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75em 16px;
}

.infoControlPanel__goToPrevious__icon,
.infoControlPanel__goToPrevious__icon--disabled {
    margin-right: 0.5em;
}

.infoControlPanel__goToPrevious__container:hover,
.infoControlPanel__goToPrevious__icon:hover {
    cursor: pointer;
}

.infoControlPanel__goToPrevious__icon--disabled > path {
    fill: #7f7f7f !important;
}

.locationData__container .ant-list-item-meta-title {
    margin-bottom: 0 !important;
}

.infoControlPanel_locationsNavigator__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.locationsNavigator__label {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.locationsNavigator_goToPrevious,
.locationsNavigator_goToNext {
    padding: 0 !important;

    /* Canceling ant default style */
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}

.edit-address-icon {
    margin-left: 24px;
}

.edit-address-icon:hover {
    cursor: pointer;
}

.editAddressForm__item__label {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.editAddressForm__item__input {
    height: 3em;
    padding: 16px !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: normal !important;
    font-stretch: normal;
    line-height: normal;
    color: #333 !important;
    letter-spacing: normal;
    background-color: #fff !important;
    border: solid 1px #999 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.editAddress__container {
    height: 20em;
}

.regenerateFootprintCheckbox__container {
    align-self: center;
}

.editAddressForm__item__input:-webkit-autofill,
.editAddressForm__item__input:-webkit-autofill:hover,
.editAddressForm__item__input:-webkit-autofill:focus,
.editAddressForm__item__input:-webkit-autofill:active,
.editAddressForm__item__input:-webkit-autofill .editAddressForm__item__input:-webkit-autofill:hover {
    height: 3em;
    padding: 16px !important;
    color: #5f5f5f !important;
    -webkit-text-fill-color: #5f5f5f;
    background-color: #fff !important;
    border: solid 1px #999 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.editAddress__form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-around;
    height: 100%;
}

.editAddress__countrySelect > .ant-select-item-option-content {
    line-height: normal;
    white-space: break-spaces;
}

div[data-testid="edit_select_country--testid"] {
    height: 3em;
}

div[data-testid="edit_select_country--testid"] > .ant-select-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100% !important;
    color: #5f5f5f¨ !important;
    background-color: #fff !important;
    border: solid 1px #999 !important;
    border-radius: 0 !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.edit_select_country .ant-select-selection-item {
    color: #333 !important;
}

.edit_select_country .ant-select-selection-search-input {
    height: 100% !important;
}

.locationData__coordinates {
    flex-grow: 1;
    max-width: 70%;
}

.locationData__coordinates--switch.ant-switch-checked {
    background-color: #5b7b73 !important;
}

.locationData__coordinates--useClientCoordinates {
    cursor: pointer;
}
