.header__container_column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
}

.header__content_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 24px 24px;
}

.header__content_column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
}

.header__content_column__mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 24px 24px;
}

.country_table {
    flex-grow: 1;
}

.country_table__mobile {
    max-width: 100%;
}

.country_table_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
    background-color: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
}

.arrow_button {
    display: flex;
}

.arrow_button__container {
    display: flex;
    justify-content: flex-end;
}

.header__information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 0;
    width: fit-content;
    padding-top: 0.5em;
}

.header__information__mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 0;
    width: fit-content;
    padding-top: 0.5em;
}

.box_line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 45px;
    width: fit-content;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
}

.info__container {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 24px;
}

.information__title {
    display: flex;
    gap: 0 8px;
    align-items: center;
    font-size: 18px;
}

.country__title {
    display: flex;
    gap: 0 8px;
    align-items: center;
    font-size: 20px;
}

.column {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333;
}

.information__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    padding: 0 16px;
    border-right: 1px solid #000;
}

.information__box:first-child {
    padding-left: 16px !important;
}

.information__box__mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    padding: 0 16px;
    border-right: 1px solid #000;
}

.line-left {
    border-left: 1px solid #000;
}

.information__box__mobile:first-child {
    padding-left: 16px !important;
}

.information__box__flex {
    flex-direction: column;
}

.locations_header__button {
    padding: 0 !important;
}

.title__container {
    position: relative;
    flex-direction: column;
}

.metrics__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.square__div {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    margin-right: 16px;
}

.square {
    width: 31px;
    height: 12px;
}

.country__table__title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 10px;
    font-weight: bold;
    border-bottom: solid 1px #000;
}

.country__table__list {
    gap: 148px;
    max-height: 285px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    overflow: auto;
}

.country__table__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 45px;
    font-size: 16px;
}

.country__table__item {
    padding: 0 !important;
    border-bottom: solid 0 #fff !important;
}

.country__table__item:hover {
    background-color: #e6f7ff;
}

.header__container_column .ant-list-item {
    height: 56px;
}

.header__container_column .ant-list-items li:nth-child(even) {
    background-color: #f5f5f5 !important;
}

.header__container_column .ant-list-items li:nth-child(even):hover {
    background-color: #e6f7ff !important;
}

.location-information-display {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.location-information-left-part {
    display: flex;
    flex-direction: column;
    width: fit-content;
}

.location-information-right-part {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.jvectormap-container svg {
    min-height: 515px;
    max-height: 566px;
}

.grey_box {
    min-height: 515px;
    max-height: 566px;
    background-color: #f2f2f2;
}
