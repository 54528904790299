.text {
    font-size: 32px !important;
    font-weight: bold !important;
}

.box {
    position: fixed;
    right: auto !important;
    left: 50%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    transform: translate(-60px, 0);
}

.loadingbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    background-color: #999;
}

.loadingline {
    width: 20px;
    height: 50px;
    background: #d24723;
    transform: skew(-40deg);
}

.loadinglineactive {
    background: #f0f0f0;
    animation: loading 1s linear infinite;
}

@keyframes loading {
    0% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}
