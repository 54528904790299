.topbar__container {
    position: absolute;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 80vw;
    height: 64px;
    margin: 16px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
}

.topbar__action {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
    margin: 8px 0;
    padding-right: 40px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    color: #00008f;
    text-transform: uppercase;
    letter-spacing: normal;
    cursor: pointer;
}

.topbar__action--disabled,
.footprintMode__mode__label--selected--disabled {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    margin: 8px 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    color: #00000026;
    text-transform: uppercase;
    letter-spacing: normal;
    cursor: not-allowed !important;
}

.topbar__editFeedback {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    text-transform: none;
    letter-spacing: normal;
    border-right: solid 1px #999;
    border-left: solid 1px #999;
}

.topbar__geocodeContainer,
.topbar__geocodeContainer--disabled {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.topbar__geocodeContainer--disabled {
    cursor: not-allowed;
}

.topbar__geocodeContainer--disabled > * {
    color: #00000026 !important;
}

.topbar__geocodeContainer .anticon-environment {
    padding-right: 0.5em;
}

.geocode__icon,
.geocode__icon--disabled {
    margin-right: 16px;
}

.geocode__icon--edit {
    margin-top: 0.5em;
    margin-left: 16px;
}

.geocode__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.geocode_value__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 14em;
    height: 32px;
    padding: 0 10px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
    cursor: pointer;
    background-color: #b5d0ee57;
    border-radius: 4px;
}

.geocode_value_label {
    cursor: text;
}

.confidence_resolution__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 10em;
}

.confidence_resolution__container--disabled {
    visibility: hidden;
}

.resolution__label {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
    margin-right: 10px;
}

.topbar__centerActions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    width: 40em;
}

.topbar__footprintMode__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.topbar__footprintMode__container--disabled {
    visibility: hidden;
}

.footprintMode__mode__label--selected--disabled {
    width: 100%;
}

.footprintMode__mode__label--selected {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #343c3d;
    text-transform: uppercase;
    letter-spacing: normal;
    cursor: pointer;
}

.coloractive {
    color: #00008f;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.footprint__mode__explanation {
    width: 20em;
    padding: 16px 24px;
    margin-bottom: 0.5em;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.footprint__mode__explanation::first-letter {
    text-transform: uppercase !important;
}

.footprint__mode__menu {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: center;
    min-width: 20em;
    padding: 0 !important;

    /* border-radius: 4px !important;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important; */
}

.footprint__mode__wrapper {
    width: 300px;
    background-color: #fff;
    border-radius: 4px !important;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%) !important;
}

.footprint__mode_change_explanation__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: #f5f5f5;
}

.footprint__mode_change_explanation__label {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.footprint__mode_change_explanation__link {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #00008f;
    text-align: right;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: normal;
}

.ant-menu-vertical.footprint__mode__menu {
    border-right: none !important;
}

.footprint__mode__item {
    padding: 0.5em 1.25em !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: solid #f0f0f0 !important;
}

.footprint__mode__item:last-child,
.footprint__mode__menu {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.footprint__mode__item:hover {
    background-color: #e5e5e5 !important;
    border-bottom: 0 solid #e5e5e5 !important;
}

.footprint__mode__item__label {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #00008f;
    text-transform: uppercase;
    letter-spacing: normal;
}

.footprintMode__mode__label--selected--icon,
.footprintMode__mode__label--selected--icon--disabled {
    margin-left: 0.5em;
}

.geocode__icon--disabled > path,
.geocode__icon--edit--icon--disabled > path,
.resetFootprint__button--icon--disabled > path,
.close__cross--icon--disabled > path,
.footprintMode__mode__label--selected--icon--disabled > path {
    fill: #00000026;
}

.close__cross--icon,
.resetFootprint__button--icon {
    margin-left: 0.5em;
}

.ant-menu-item-selected.ant-menu-item-only-child.footprint__mode__item {
    background-color: #f5f5f5 !important;
    border-bottom: none !important;
}

.footprint_error__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 16px;
}

.footprint_error__label {
    margin-left: 0.5em;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #d24723;
    text-transform: initial;
    letter-spacing: normal;
}

.error_popconfirm_label__container {
    width: 10em;
    cursor: pointer;
}

.footprint_error_close--icon {
    cursor: pointer;
}

.error_popconfirm__container .ant-popover-message-title {
    width: 25em;
    padding-left: 0;
}

.error_popconfirm__container .ant-popover-message {
    padding: 0;
}

.error_popconfirm__container .ant-popover-inner {
    border-radius: 4px !important;
}

.error_popconfirm__container .ant-popover-arrow-content {
    background-color: #d24723;
}

.footprint_error_header__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0.5em 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #fff;
    letter-spacing: normal;
    background-color: #d24723;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.footprint_error_content__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    align-items: flex-start;
    justify-content: space-around;
    height: auto;
    padding: 16px;
    border-bottom: 1px solid #999;
}

.error_popconfirm__container .ant-popover-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    padding: 0.5em 16px;
}

.footprint_error_description {
    margin-left: 0;
    white-space: pre-line !important;
}

.footprint_error_buttons {
    margin-left: 0 !important;
}

.footprint_error_copyButton svg {
    fill: #fff;
}

.footprint_error_copyButton:active svg,
.footprint_error_copyButton:hover svg,
.footprint_error_copyButton:focus svg {
    fill: #00008f;
}

.footprint_error_copyButton:disabled svg,
.footprint_error_copyButton:disabled:hover svg,
.footprint_error_copyButton:disabled:focus svg {
    fill: #00000040;
}

.topbar__validationStatus__container .validationStatus__label {
    font-size: 16px;
}

.dropdown_change_mode {
    display: flex;
    height: 100%;
    padding: 19px 16px 5px;
    overflow-wrap: break-word;
}

.dropdown_more_mode {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    border-top: solid #f0f0f0 !important;
}

.ant-dropdown-menu-item {
    padding: 0 !important;
}

.ant-menu-item {
    height: auto !important;
}

.ant-dropdown-menu-item:hover {
    background-color: #f0f0f0 !important;
}

.dropdown_button {
    display: flex !important;
    flex-direction: row;
    gap: 8px;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
}

.dropdown_button_text {
    font-weight: 600;
}

.ant-btn-link:active > svg > path,
.ant-btn-link:hover > svg > path,
.ant-btn-link:focus > svg > path {
    fill: #00008f !important;
}

.ant-dropdown-menu {
    width: 300px;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fff !important;
    box-shadow: none !important;
}

.popup_button {
    display: flex !important;
    align-items: center !important;
    justify-content: right !important;
    width: 100%;
}

.popup_title {
    font-weight: 600;
}

.popup_underline {
    text-decoration-line: underline;
}

.popup_actions {
    display: flex !important;
    flex-direction: row;
}

.popup {
    margin-bottom: 20px;
    font-size: 14px !important;
}

.dropdown-title {
    margin-left: 40px;
}

.dropdown-icon {
    padding-right: 24px;
}

.ant-dropdown {
    top: 80px !important;
}

.banner__container {
    position: absolute;
    top: 0;
    z-index: 10;
    min-width:47vw;
    padding-left: 2vw;
    padding-right: 2vw;
    max-width: 82vw;
    margin: 80px;
    /* text-align: center; */
}

.banner__text{
    color:white;
    font-size:16px;
    height: 40px;
    background-color: #4976BA;
    text-align: center;
    padding-left: 2vw;
    padding-right: 2vw;
}

.banner__info__logo{
    margin-right:10px ;
    align-items: center;
    position:relative;
    top: 6px;
}

.banner__link{
    text-decoration: underline;
    color:white
}