body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Source Code Pro", sans-serif;
    background: #fafafa;
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        "Courier New",
        monospace;
}

* {
    font-family: "Source Sans Pro", sans-serif;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

button,
button:active,
button:focus {
    border: none;
    outline: 0;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: none;
    border: none;
    border-radius: 30px;
    outline: none;
    box-shadow:
        inset 1px 2px 7px 0 #cbced1,
        inset 6px -4px 5px #fff;
    transition: background-color 5000s ease-in-out 0s;
}
