.action__searchBar {
    display: flex;
    flex-direction: row;
    gap: 9px;
}

.job__leftPart {
    display: flex;
    flex-direction: row;
    gap: 3em;
    justify-content: space-between;
    width: 100%;
}

.job__leftPart-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    margin-right: 3em;
}

.uploadPopover__button {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.uploadPopover__button > .anticon-folder-view {
    margin-right: 0 !important;
}

.uploadPopover__button > .anticon-folder-view > svg {
    width: 24px !important;
    height: 24px !important;
}
