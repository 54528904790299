.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #00008f !important;
}

.ant-table-content > table {
    border-collapse: collapse !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #00008f !important;
    border-color: #00008f !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #00008f !important;
}

.ant-checkbox-checked::after {
    border-color: #b5d0ee !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked:hover {
    border-color: #00008f !important;
}

.ant-btn-link {
    color: #00008f !important;
}

::-webkit-scrollbar {
    appearance: none;
    width: 4px !important;
    height: 4px !important;
}

::-webkit-scrollbar-thumb {
    background-color: #00008f !important;
    border-radius: 4px !important;
    box-shadow: 0 0 1px rgb(255 255 255 / 50%) !important;
}

.page__container {
    padding: 32px 24px;
}

.page__actionsContainer {
    display: flex;
    flex-basis: 100px;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    padding: 24px;
    margin: 24px 0;
    background-color: white;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.page__actionsContainer-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
    height: auto;
    padding: 32px;
    margin-top: 16px;
    margin-bottom: 32px;
    background-color: white;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.action__searchContainer .ant-input-group-addon {
    display: none;
}

.action__quickFiltersContainer {
    width: 314px;
}

.action__searchContainer {
    flex-grow: 2;
}

.action__searchContainer-column {
    display: flex;
    justify-content: column;
}

.searchContainer__input--withLabel {
    width: 50% !important;
    min-width: 200px;
}

.searchContainer__input--width {
    width: 100% !important;
}

.filters__ctas__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.filters__ctas__title_icon {
    margin-right: 5px;
    font-size: 25px;
    color: #656565 !important;
}

.filters__ctas__container {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.filters__ctas__container > button {
    margin-right: 12px;
}

.ant-table {
    background-color: #fafafa !important;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: unset !important;
}

.pages__table__row--even {
    background-color: #f0f0f0 !important;
}

.pages__table__row {
    background-color: #fafafa !important;
}

.pages__table__row--disabled {
    cursor: not-allowed !important;
}

.pages__table__row:hover {
    cursor: pointer;
    background-color: rgb(181 208 238 / 20%) !important;
    border-left: 6px solid #00008f;
}

.page__dataTableContainer .ant-table-header {
    border-bottom: 1px solid #757575;
}

.page__dataTableContainer .ant-table-cell {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #343c3d;
    text-align: start;
    letter-spacing: normal;
}

.page__dataTableContainer .ant-table-column-title,
.page__dataTableContainer thead .ant-table-cell {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #343c3d;
    text-align: start;
    letter-spacing: normal;
}

.page__dataTableContainer .ant-tag {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 0.5em 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    text-align: left;
    letter-spacing: normal;
    border-radius: 24px;
}

.page__dataTableContainer .ant-table table {
    border-collapse: collapse;
}

.page__dataTableContainer .ant-table-thead > tr > th {
    background-color: #fff !important;
    border-bottom: 1px solid #757575;
}

.page__dataTableContainer td.ant-table-column-sort {
    background: none;
}

.page__dataTableContainer .ant-pagination-item,
.page__dataTableContainer .ant-pagination-item-active {
    border: #fafafa;
}

.page__dataTableContainer .ant-pagination-item a {
    font-size: 14px !important;
    font-weight: 600;
    color: #00008f;
    background-color: #fafafa !important;
    border: #fafafa;
    border-bottom: 3px solid #fafafa !important;
}

.page__dataTableContainer .ant-pagination-item-active a {
    font-size: 14px !important;
    font-weight: 600;
    color: #d24723;
    background-color: #fafafa !important;
    border: #fafafa;
    border-bottom: 3px solid #d24723 !important;
}

.page__dataTableContainer .ant-pagination-item:hover,
.page__dataTableContainer .ant-pagination-item-active:hover {
    border: none;
    border-bottom: 1px solid #00008f !important;
}

.page__dataTableContainer .ant-pagination-item:hover a {
    font-weight: bold !important;
    color: #00008f;
    border-bottom: 3px solid #00008f !important;
}

.page__dataTableContainer .ant-pagination-item-active:hover a {
    font-weight: bold !important;
    color: #d24723;
    border-bottom: 3px solid #d24723 !important;
}

.page__dataTableContainer .ant-pagination-item-link-icon {
    color: #fff !important;
}

.page__dataTableContainer .ant-pagination-prev:hover .ant-pagination-item-link,
.page__dataTableContainer .ant-pagination-next:hover .ant-pagination-item-link {
    color: #00008f;
    border-color: #00008f;
}

.ant-select-selector {
    border-color: #757575 !important;
}

.ant-select-selection-placeholder {
    color: #757575 !important;
}

.ant-input-affix-wrapper input::placeholder {
    color: #757575;
}

.ant-pagination-item-ellipsis,
.ant-pagination-item-ellipsis:hover {
    height: 14px;
    color: #00008f !important;
    border-color: #00008f !important;
}

.quickFilter--active {
    font-weight: 700 !important;
    color: #fff !important;
    background: #00008f !important;
    border-color: #00008f !important;
}

.page__separatorContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.page__separatorContainer--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 19%);
}

.separator__titleContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.ant-input-affix-wrapper {
    border-color: #757575 !important;
}

.ant-input-search {
    color: #757575 !important;
}

.actions__title {
    margin-bottom: 32px !important;
}

.actions__title-column {
    margin-bottom: 8px !important;
}

.page__subtitle {
    margin-right: 16px;
}

.separator__titleContainer > .page__subtitle {
    margin-bottom: 0 !important;
    font-size: 22px !important;
    color: #000 !important;
}

.page__selectionDetails__span {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-style: italic !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    color: #333 !important;
    letter-spacing: normal !important;
}

.page__titleActionContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.page_refreshActionsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 16px 18px;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
}

.refreshActions_disclaimer {
    display: flex;
    flex-direction: column;
    flex-grow: 8;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
    margin-left: 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.ballpark__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    background-color: #b5d0ee33;
}

.ballpark__icon {
    margin-right: 0.5em;
}

.ballpark-description {
    margin-bottom: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.page_refreshActions__description {
    margin-bottom: 0;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

.refreshActions_icon {
    width: 4em;
    height: 4em;
}

.page_refreshActions_paragraph--emphasize {
    font-weight: 600;
    color: #00008f;
}

.actions-footprint__button {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.actions-footprint__button:hover {
    color: #3d3dda;
}

.actions-footprint__icon,
.actions-footprint__icon--disabled {
    width: 16px;
    height: 16px;
}

.actions-footprint__icon--disabled > path {
    fill: #00000026;
}

.actions__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: space-evenly;
    width: 100%;
}

.pagination {
    margin: 16px 32px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #00008f;
}

.pagination:hover {
    color: #00008f;
}

.pagination[disabled] {
    color: #757575 !important;
}

.pagination_ellipsis,
.pagination:hover {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #00008f !important;
}

.ant-table-pagination.ant-pagination {
    margin: 24px 0 !important;
}

.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
    margin-left: 0 !important;
}
