.noselect {
    cursor: default;
    -webkit-touch-callout: none;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
    user-select: none;
}

.number {
    font-size: 24px;
    color: #333;
}
