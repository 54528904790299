.bars {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-content: flex-end;
    width: 5em;
    height: 100%;
}

.bar {
    align-self: flex-end;
    width: 20px;
    background-color: #3B3FD8;
    border-radius: 1px;
    animation: show-bar-vertical 1.2s 0.1s forwards;
}

.bar-one--white,
.bar-two--white,
.bar-three--white {
    background-color: #fff;
}

.bar-one-off--white,
.bar-two-off--white,
.bar-three-off--white {
    background-color: rgb(255 255 255 / 40%);
}

.bar-one,
.bar-one--white {
    height: 0.5em;
}

.bar-one-off {
    height: 0.5em;
    background-color: #ccc;
}

.bar-two,
.bar-two--white {
    height: 0.75em;
}

.bar-two-off {
    height: 0.75em;
    background-color: #ccc;
}

.bar-two-off--white {
    height: 0.75em;
}

.bar-three,
.bar-three--white {
    height: 16px;
}

.bar-three-off {
    height: 16px;
    background-color: #ccc;
}

.bar-three-off--white {
    height: 16px;
}

.bars__and__labels {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em 0;
}

.confidence__labels {
    margin-left: 16px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    color: #333;
    letter-spacing: normal;
}

@keyframes show-bar-vertical {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
