.container {
    display: flex;
    flex-direction: row;
    width: 300px !important;
    padding: 20px 0;
    overflow-wrap: break-word !important;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 26px;
}

.div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.subtitle {
    margin-bottom: 0 !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
